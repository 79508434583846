<template>
  <section class="mt-2">  

     <EventLog :organisation="organisation" type="organisation"></EventLog>

  </section>
</template>
<script>
import EventLog from '@/components/EventLog.vue';
export default {
  components: {  EventLog },
  props: ["organisation"],
  data() {
    return {
    };
  },
  methods: {
  },
  mounted: function() {
  }
};
</script>
<style></style>
